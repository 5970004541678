<template>

  <b-card title="Alistamiento de pedido">

    <b-row>
      <b-col cols="12">

        <b-tabs
          v-if="orderId"
          pills
        >

          <!-- Tab: Account -->
          <b-tab active>
            <template #title>
              <feather-icon
                icon="AlertTriangleIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Agregar novedad</span>
            </template>
            <add-newness-card
              :order-id="orderId"
              class="mt-2 pt-75"
            />
          </b-tab>
          
        </b-tabs>

        
      </b-col>
    </b-row>

  </b-card>
  
</template>

<script>
import router from '@/router'
import { BRow, BCol, BTabs, BTab, BCard } from 'bootstrap-vue'
import AddNewnessCard from './AddNewnessCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,

    AddNewnessCard,
  },
  data() {
    return {
      orderId: null,
    }
  },
  created() {
    this.orderId = router.currentRoute.params.order_id
    console.log(this.orderId)
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>